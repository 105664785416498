.sevice-img{
    height: 200px;
}
.main-container{
    margin-top: 100px;
}
.main-Heading{
    background-color: #4085f5;
}
.main-Heading{
    color: aliceblue;
    padding: 10px;
    text-align: center;
}
.service-main-container{
    text-align: center;
}
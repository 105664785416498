.card-img-top{
    /* margin-top: 100px; */
    /* border: 1px solid #4085f5; */
    /* border-radius: 10px; */
}
.Card-Container{
    /* height: 320px; */
    /* padding: 10px; */
    border: 1px solid #4085f5;
}
.main-card-container{
    margin-top: 10px;
    /* width: 100vw; */
}
.main-sub-heading{
    color: #4085f5;
    text-align: center;
    font-size:20px;
}
.Main-text{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.compant-name{
    font-size: 20px;
    font-weight: 650;
}